/*============== footer-area css ===============*/
.footer-area {
  background: $dip;
}

.single-footer-widget {
  p {
    margin-bottom: 8px;
    color: white;
    opacity: .6;
  }

}

.ab_wd {
  p {
    max-width: 230px;
  }
}

.contact_wd {
  margin-right: -15px;

  p {
    margin-bottom: 15px;
  }

  a {
    display: block;
    color: $baseColor;
    font-size: 24px;
    font-family: $rob;
    font-weight: 500;
    margin-bottom: 10px;

    & + a {
      margin-bottom: 0px;
    }
  }
}

.tp_widgets {
  .list {
    li {
      margin-bottom: 12px;

      a {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        font-family: $rob;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.news_widgets {
  p {
    color: #fff;
    opacity: .6;
  }
}

.footer_title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  font-family: $open;
  color: #fff;
  margin-bottom: 28px;
  text-transform: uppercase;
}

.border_line {
  width: 100%;
  height: 1px;
  background: #333333;
  margin-top: 30px;
}

.footer-bottom {
  padding-top: 40px;

  .footer-text {
    color: #fff;
    opacity: .6;

    i {
      color: $secondaryColor;
      opacity: 1;
    }

    a {
      color: $secondaryColor;
      opacity: 1;
    }
  }

  .footer-links {
    text-align: right;

    a {
      color: white;
      margin-left: 1rem;
      opacity: .6;

      &:hover {
        opacity: 1;
      }
    }
  }
}