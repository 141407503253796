/* Price Area css
============================================================================================ */
.price_area{
	background: #f9f9ff;

	.col-lg-4, .col-md-6 {
		margin-bottom: 20px;
	}

}
.price_item_inner{
	
}
.price_item{
	background: #fff;
	height: 100%;
	.price_head{
		overflow: hidden;
		border-bottom: 1px solid #eeeeee;
		padding: 30px 20px 20px 20px;
		h2{
			text-align: center;
			margin: 0;
		}
	}
	.price_body{
		padding: 20px;
		p {
			text-align: justify;
		}

		.category {
			margin-top: 8px;
			& > div {
				font-weight: bold;
			}
		}
	}
	.price_footer{
		text-align: center;
		padding-bottom: 40px;
	}
}
/* End Price Area css
============================================================================================ */