/* Home Banner Area css
============================================================================================ */
.home_banner_area {
  z-index: 1;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $baseColor;
    opacity: 0.7;
  }

  background: url(../img/banner/home-banner.jpg) no-repeat scroll center center;
  background-size: cover;

  .banner_inner {
    overflow: hidden;
    width: 100%;
    padding: 170px 0px 140px 0px;

    .banner_content {
      color: #fff;

      .col-lg-7 {
        vertical-align: middle;
        align-self: center;
      }

      h1 {
        @include responsive-font-size(48px);
        font-weight: bold;
        font-family: $open;
        margin-bottom: 15px;
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) {
          margin-top: -30px;
        }
      }

      p {
        color: #fff;
        font-family: $rob;
        font-weight: 300;
        max-width: 450px;
        margin-bottom: 40px;
        font-size: 14px;
      }

      .banner_map_img {
        text-align: right;
      }

      .banner_btn {
        background-color: $secondaryColor;
        color: #fff;

        &:hover {
          background-color: lighten($secondaryColor, 10%);
        }
      }
    }
  }

}

.blog_banner {
  min-height: 780px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 0px;

  .banner_inner {
    background: #04091e;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 780px;
    z-index: 1;

    .overlay {
      background: url(../img/banner/banner-2.jpg) no-repeat scroll center center;
      opacity: .5;
      height: 125%;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      z-index: -1;
    }

    .blog_b_text {
      max-width: 700px;
      margin: auto;
      color: #fff;
      margin-top: 40px;

      h2 {
        font-size: 60px;
        font-weight: 500;
        font-family: $rob;
        line-height: 66px;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        margin-bottom: 35px;
      }

      .white_bg_btn {
        line-height: 42px;
        padding: 0px 45px;
      }
    }
  }
}

.banner_box {
  max-width: 1620px;
  margin: auto;
}

.banner_area {
  position: relative;
  z-index: 1;
  min-height: 400px;
  background-image: -moz-linear-gradient(0deg, $baseColorLight 0%, $baseColor 100%);
  background-image: -webkit-linear-gradient(0deg, $baseColorLight 0%, $baseColor 100%);
  background-image: -ms-linear-gradient(0deg, $baseColorLight 0%, $baseColor 100%);

  .banner_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 400px;
    z-index: 1;

    .banner_content {
      margin-top: 85px;

      h1 {
        color: #fff;
        font-size: 48px;
        font-family: $rob;
        margin: 0 auto;
        font-weight: 500;
        max-width: 700px;
      }

      .page_link {
        font-size: 14px;
        color: #fff;
        font-family: $rob;
        margin-right: 32px;
        position: relative;
        text-transform: uppercase;

      }
    }
  }
}


/* End Home Banner Area css
============================================================================================ */