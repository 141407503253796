/* Main Button Area css
============================================================================================ */
.main_btn{
	padding: 0px 45px;
    background-image: linear-gradient(to right, #bc2bff 0%, #7249fb 51%, #bc2bff 100%);
    background-size: 200% auto;
    z-index: 2;
    display: inline-block;
    transition: all 400ms linear;
    text-align: center;
    color: #fff;
    font-size: 13px;
	font-weight: 600;
    font-family: $open;
    line-height: 50px;
    border-radius: 5px;
	box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
    &:hover{
        background-position: right center;
        color: #fff;
    }
}
.main_btn2{
	display: inline-block;
	background: #f9f9ff;
	padding: 0px 45px;
	color: $dip;
	font-family: $open;
	font-size: 13px;
	font-weight: 600;
	line-height: 48px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	border: 1px solid #dddddd;
	cursor: pointer;
	transition: color 0ms linear;
	&:hover{
		background-image: -moz-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		background-image: -webkit-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		background-image: -ms-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		box-shadow: 0px 10px 30px 0px rgba(115, 73, 251, 0.3);
		color: #fff;
	}
}
.submit_btn{
	width: auto;
	display: inline-block;
	background-image: linear-gradient(to right, #bc2bff 0%, #7249fb 51%, #bc2bff 100%);
    background-size: 200% auto;
	padding: 0px 50px;
	color: #fff;
	font-family: $rob;
	font-size: 13px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 5px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	cursor: pointer;
	@include transition;
	&:hover{
		background-position: right center;
        color: #fff;
	}
}

.white_btn{
	display: inline-block;
	background: #f9f9ff;
	padding: 0px 50px;
	color: $dip;
	font-family: $rob;
	font-size: 12px;
	font-weight: 500;
	line-height: 50px;
	border-radius: 0px;
	outline: none !important;
	box-shadow: none !important;
	text-align: center;
	cursor: pointer;
	@include transition;
	&:hover{
		background: $baseColor;
		color: #fff;
	}
}
.banner_btn{
	padding: 0px 45px;
	line-height: 50px;
	background: #fff;
	color: $dip; 
	display: inline-block;
	border-radius: 5px;
	font-size: 13px;
	font-family: $open;
	font-weight: 600;
	@include transition;
	&:hover{
		color: $dip;
		box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.1);
	}
}
.white_bg_btn{
	background: #fff;
	display: inline-block;
	color: $dip;
	line-height: 40px;
	padding: 0px 28px;
	font-size: 14px;
	font-family: $rob;
	font-weight: 500;
	text-transform: uppercase;
	@include transition;
	border: none;
	&:hover{
		background: $baseColor;
		color: #fff;
	}
}
.blog_btn{
	border: 1px solid #eeeeee;
	background: #f9f9ff;
	padding: 0px 32px;
	font-size: 13px;
	font-weight: 500;
	font-family: $rob;
	border-radius: 5px;
	color: $dip;
	line-height: 34px;
	display: inline-block;
	&:hover{
		background-image: -moz-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		background-image: -webkit-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		background-image: -ms-linear-gradient( 0deg, rgb(188,43,255) 0%, rgb(114,73,251) 100%);
		border-color: #fff;
		color: #fff;
	}
}
/* End Main Button Area css
============================================================================================ */