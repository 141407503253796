@import "bootstrap/bootstrap";
/*----------------------------------------------------*/
@import "variables";
/*---------------------------------------------------- */ 

/*----------------------------------------------------*/
@import "predefine";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "header";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "breadcrumb";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "blog";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "contact";
/*---------------------------------------------------- */ 

/*----------------------------------------------------*/
@import "elements";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "button";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "feature";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "video";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "price";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "testimonials";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "footer";
/*---------------------------------------------------- */
/*----------------------------------------------------*/
@import "faq";
/*---------------------------------------------------- */






