.list{
    list-style: none;
    margin: 0px;
    padding: 0px;
}

a{
    text-decoration:none;
    transition: all 0.3s ease-in-out;
    &:hover, &:focus{
       text-decoration:none;
       outline: none;
    }
}
.row.m0{
    margin: 0px;
}

body{
    line-height: 24px;
    font-size: 14px;
    font-family: $rob;
    font-weight: normal;
    color: $pfont;
}
h1, h2, h3, h4, h5, h6{ 
    font-family: $open; 
    font-weight: 600;
}

button:focus{
    outline: none;
	box-shadow: none;
}

.p_40 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.p_120{
	padding-top: 120px;
	padding-bottom: 120px;
}
.pad_top{
	padding-top: 120px;
}
.mt-25{
	margin-top: 25px;
}
.p0{
	padding-left: 0px;
	padding-right: 0px;
}
.container{
    @media(min-width:1200px){
        max-width: 1170px;
    }
}
@media(min-width: 1620px){
	.box_1620{
		max-width: 1650px;
		margin: auto;
	}
}


/* Main Title Area css
============================================================================================ */
.main_title{
	text-align: center;
	margin-bottom: 75px;
	h2{
		font-family: $open;
		font-size: 36px;
		color: $dip;
		font-weight: 600;
		margin-bottom: 15px;
	}
	p{
		font-size: 14px;
		font-family: $rob;
		line-height: 24px;
		color: $pfont;
		margin-bottom: 0px;
		max-width: 700px;
		font-weight: normal;
		margin: auto;
	}
	&.white{
		h2{
			color: #fff;
		}
		p{
			color: #fff;
			opacity: .6;
		}
	}
}

/* End Main Title Area css
============================================================================================ */



