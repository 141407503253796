/*font Variables*/
$rob: 'Roboto', sans-serif;
$open: 'Open Sans', sans-serif;

$secondary: #C2185B;

/*Color Variables*/
$baseColor: #004D40;
$baseColorLight: #006154;
$secondaryColor: #C2185B;
$secondaryColorDark: #a11851;
$dip: #3d3d3d;
$pfont: #777777; 


/*=================== fonts ====================*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:300,300i,400,500,700');

// Mixins
@mixin transition($property: all, $duration: 300ms, $animate: linear, $delay:0s){
    transition: $property $duration $animate $delay; 
}

// Placeholder Mixins

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}




